import { DefaultSeo } from "next-seo";
import { AppProps } from "next/app";
import { Inter } from "next/font/google";
import Head from "next/head";
import React from "react";

import { Providers } from "../app/providers";

import { getSeo } from "~/utils/seo";

const inter = Inter({ subsets: ["latin"] });

const App = ({ Component, pageProps }: AppProps) => {
  const seo = getSeo();

  return (
    <>
      <Head>
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon.png" />
        <meta name="theme-color" content="#427CFF" />
        <style
          dangerouslySetInnerHTML={{
            __html: `
              :root { --chakra-fonts-body: ${inter.style.fontFamily} !important }
            `,
          }}
        />
      </Head>

      <DefaultSeo {...seo} />
      <Providers>
        <Component {...pageProps} />
      </Providers>
    </>
  );
};

export default App;
