export const siteConfig = {
  copyright: "Copyright © 2024 Rollout. All Rights Reserved.",
  seo: {
    title: "Rollout",
    titleTemplate: "%s - Rollout",
    description: "Embedded integrations platform for self-serve.",
    siteUrl: "https://docs.rollout.com",
    openGraph: {
      type: "website",
      locale: "en_US",
      url: "https://docs.rollout.com",
      title: "Rollout",
      description: "Embedded integrations platform for self-serve.",
      site_name: "Rollout: Embedded integrations platform for self-serve.",
    },
  },
};
